import {
  trackPageViewV2,
  trackEvent,
  trackApplicationEvent,
  AnalyticsEvent,
} from "@/services/analytics-adapter";
import {
  HealthCheckProvider,
  HealthCheckQuestionnaireData,
} from "@/services/core-api-adapter";
import { Stack, Typography } from "@mui/material";
import { useMachine } from "@xstate/react";
import { lazy, useEffect, useState, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import AvailableHealthChecks from "@/components/AvailableHealthChecks";
import DefaultError from "@/components/DefaultError";
import FlowHeader from "@/components/FlowHeader";
import FullscreenLoadingIndicator from "@/components/FullscreenLoadingIndicator";
import PreScanQuestionnaireFlow from "./components/PreScanQuestionnaireFlow";
import Prompt from "./components/Prompt";
import ScanInstructions from "./components/ScanInstructions";
import UnsupportedDeviceError from "./components/UnsupportedBrowserError";
import { binahScanFlowStateMachine, eventNames } from "./machine";
import { HealthMonitorCodes } from "@binah/web-sdk";

interface BinahScanFlowProps {
  onCompletedFlow?: () => void;
}

const HEAlTH_CHECK_PROVIDER =
  import.meta.env.VITE_APP_HEALTH_CHECK_PROVIDER || HealthCheckProvider.DEFAULT;

export const PRE_SCAN_USER_QUESTIONS = 2;

const BinahMeasure = lazy(() => import("./components/Measure"));

const initialiseMonitor = () => {
  async function dynamicallyLoadMonitor() {
    const monitor = (await import("@binah/web-sdk")).default;
    return monitor;
  }

  dynamicallyLoadMonitor().then((monitor) => {
    if (window.Cypress) {
      window.binahScanMonitor = monitor;
    }
  });
};

initialiseMonitor();

enum ErrorMap {
  UNSUPPORTED_BROWSER_IOS = "UNSUPPORTED_BROWSER_IOS",
  UNSUPPORTED_BROWSER_ANDROID = "UNSUPPORTED_BROWSER_ANDROID",
}

interface DeviceCompatibilityErrorProps {
  errorType: any;
  onComeBackLater: () => void;
  onTryAgain?: () => void;
}

function DeviceCompatibilityError({
  errorType,
  onComeBackLater,
  onTryAgain,
}: DeviceCompatibilityErrorProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    trackPageViewV2({
      pageName: "Health:Health check device compatibility error",
      pageSubSection1: "Health",
      pageSubSection2: "Health:Health check device compatibility error",
      pageCategory: "Health",
    });
  }, []);

  switch (errorType) {
    case ErrorMap.UNSUPPORTED_BROWSER_IOS:
      return (
        <UnsupportedDeviceError
          onComeBackLater={onComeBackLater}
          deviceOS="IOS"
        />
      );
    case ErrorMap.UNSUPPORTED_BROWSER_ANDROID:
      return (
        <UnsupportedDeviceError
          onComeBackLater={onComeBackLater}
          deviceOS="ANDROID_OS"
        />
      );
  }

  const onGenericErrorTryAgain = () => {
    if (onTryAgain) {
      return onTryAgain;
    } else {
      return navigate("/home");
    }
  };

  return (
    <Stack height="100vh" justifyContent="end">
      <DefaultError onComeBackLater={onGenericErrorTryAgain}>
        <Typography variant="h2">{t("GenericError.title")}</Typography>
        <Typography pb={2}>{t("GenericError.content")}</Typography>
      </DefaultError>
    </Stack>
  );
}

export default function BinahScanFlow({ onCompletedFlow }: BinahScanFlowProps) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [scanQuestionnaireFlowIndex, setScanQuestionnaireFlowIndex] =
    useState(1);

  const [stateMachineState, dispatchStateMachineEvent] = useMachine(
    binahScanFlowStateMachine,
    {
      input: {
        shouldSkipScanPrompt: Boolean(searchParams.get("skipScanPrompt")),
      },
    }
  );

  function onContinueButtonClick() {
    dispatchStateMachineEvent({ type: eventNames.CONTINUE_BUTTON_CLICKED });
  }

  function onGoHomeButtonClick() {
    dispatchStateMachineEvent({ type: eventNames.GO_HOME_BUTTON_CLICKED });
  }

  function onPreScanComplete(
    healthCheckQuestionnaireData: HealthCheckQuestionnaireData
  ) {
    dispatchStateMachineEvent({
      type: eventNames.CONTINUE_BUTTON_CLICKED,
      input: healthCheckQuestionnaireData,
    });
  }

  function onNextQuestionHandler(questionIndex: number) {
    setScanQuestionnaireFlowIndex(questionIndex);
  }

  function onConfirmScanInstructions() {
    trackEvent({
      event: "action.faceScanInstructionsContinue",
      provider: HealthCheckProvider.INTERCARE,
      source: "Health check",
    });
    dispatchStateMachineEvent({ type: eventNames.CONTINUE_BUTTON_CLICKED });
  }

  function onScanInstructionsComeBackLater() {
    trackEvent({
      event: "action.faceScanInstructionsComeBackLater",
      source: "Health check",
    });
    onDecline();
  }

  function onDecline() {
    dispatchStateMachineEvent({ type: eventNames.DECLINE_BUTTON_CLICKED });
  }

  function onCompletedScan() {
    if (onCompletedFlow) {
      onCompletedFlow();
    }
  }

  function onScanRetry(errorCode: HealthMonitorCodes | null | undefined) {
    trackEvent({
      event: "action.faceScanRetry",
      source: "Health check",
      ...(errorCode && {
        errorCode: errorCode,
      }),
    });
    dispatchStateMachineEvent({ type: eventNames.TRY_AGAIN_BUTTON });
  }

  function onScanComeBackLater(
    errorCode: HealthMonitorCodes | null | undefined
  ) {
    trackEvent({
      event: "action.faceScanComeBackLater",
      source: "Health check",
      ...(errorCode && {
        errorCode: errorCode,
      }),
    });
    dispatchStateMachineEvent({ type: eventNames.GO_HOME_BUTTON_CLICKED });
  }

  useEffect(() => {
    if (stateMachineState.matches("scanCompleted")) {
      onCompletedScan();
    }

    if (stateMachineState.matches("showPreScanQuestionnaire")) {
      trackApplicationEvent(AnalyticsEvent.APPLICATION_START, {
        applicationName: "Application: health check",
        applicationStep: "step 1",
      });
    }
  }, [stateMachineState]);

  return (
    <Stack sx={{ height: "100%" }}>
      {(stateMachineState.matches("fetchingMemberships") ||
        stateMachineState.matches("fetchingServiceOfferingUsage")) && (
        <FullscreenLoadingIndicator mainIconType="face" />
      )}

      {stateMachineState.matches("showPreScanQuestionnaire") && (
        <FlowHeader
          title={t("MemberRequiredActionsFlow.BINAH_SCAN.title")}
          value={scanQuestionnaireFlowIndex}
          max={PRE_SCAN_USER_QUESTIONS}
          isBackButtonVisible={false}
        />
      )}

      {stateMachineState.matches("showScanPrompt") && (
        <Prompt onAccept={onContinueButtonClick} onDecline={onDecline} />
      )}

      {stateMachineState.matches("showProductRules") && (
        <AvailableHealthChecks
          availableServiceOfferingUsageCount={
            stateMachineState.context.availableServiceOfferingUsageCount
          }
          serviceOfferingUsageList={
            stateMachineState.context.serviceOfferingUsageList
          }
          needsMedicalAssistance={false}
          onContinue={onContinueButtonClick}
          onBack={onGoHomeButtonClick}
        />
      )}

      {stateMachineState.matches("showPreScanQuestionnaire") && (
        <PreScanQuestionnaireFlow
          onNextQuestion={onNextQuestionHandler}
          onComplete={onPreScanComplete}
        />
      )}

      {stateMachineState.matches("showPreScanInstructions") && (
        <ScanInstructions
          provider={HEAlTH_CHECK_PROVIDER}
          onConfirm={onConfirmScanInstructions}
          onComeBackLater={onDecline}
          onBackButtonClick={onScanInstructionsComeBackLater}
        />
      )}

      {stateMachineState.matches("showDeviceCompatibilityError") && (
        <DeviceCompatibilityError
          onComeBackLater={onGoHomeButtonClick}
          errorType={stateMachineState.context.deviceCompatibilityError}
        />
      )}

      {stateMachineState.matches("startingScan") && (
        <Suspense fallback={<FullscreenLoadingIndicator mainIconType="face" />}>
          <BinahMeasure
            onCompletedScan={onCompletedScan}
            onScanRetry={onScanRetry}
            healthCheckQuestionnaireData={
              stateMachineState.context.healthCheckQuestionnaireData
            }
            onComeBackLaterButtonClick={onScanComeBackLater}
          />
        </Suspense>
      )}

      {stateMachineState.matches("exit") && <Navigate to="/" />}

      {stateMachineState.matches("error") && (
        <Stack height="100vh" justifyContent="end">
          <DefaultError onComeBackLater={onGoHomeButtonClick}>
            <Typography variant="h2">{t("GenericError.title")}</Typography>
            <Typography pb={2}>{t("GenericError.content")}</Typography>
          </DefaultError>
        </Stack>
      )}
    </Stack>
  );
}
