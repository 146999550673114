import FullscreenBackground from "@/components/FullscreenBackground";
import InformationBox from "@/components/InformationBox";
import { trackEvent } from "@/services/analytics-adapter";
import { FaceIconBrand } from "@/theme/icons";
import { Stack, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import PersonDoingFaceScanImage from "./person-doing-facescan.png";
import ContentPanelTopCurveImage from "./content-panel-top-curve.svg";
import ButtonWithAnalytics from "@/components/ButtonWithAnalytics";

interface PromptProps {
  onAccept: () => void;
  onDecline: () => void;
}

export default function Prompt(props: PromptProps) {
  const { t } = useTranslation();

  function onDeclineHealthCheckButtonClick() {
    trackEvent({
      event: "action.healthCheckPromptRejected",
      source: "health check",
    });

    props.onDecline();
  }

  function onContinueHealthCheckButtonClick() {
    trackEvent({
      event: "action.healthCheckPromptAccepted",
      source: "health check",
    });

    props.onAccept();
  }

  return (
    <>
      <Stack
        justifyContent="space-between"
        sx={{
          flex: "1 1 auto",
          overflowX: "hidden",
          position: "relative",
        }}
      >
        <Stack
          p={2}
          color="primary.contrastText"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          mb={5}
        >
          <Typography color="inherit" variant="h1" fontWeight={400}>
            {t("BinahScanFlow.prompt.heading")}
          </Typography>

          <Stack direction="row" spacing={1} color="primary.contrastText">
            <Typography color="inherit" variant="h1">
              {t("common.appName")}
            </Typography>
            <FaceIconBrand sx={{ fontSize: (theme) => theme.spacing(5) }} />
          </Stack>

          <Typography
            textAlign="center"
            color="inherit"
            variant="h3"
            fontWeight={200}
          >
            {t("BinahScanFlow.prompt.subHeading")}
          </Typography>
        </Stack>

        <Box
          src={ContentPanelTopCurveImage}
          component="img"
          sx={{
            zIndex: 2,
          }}
        />

        <Stack
          spacing={2}
          flexGrow={1}
          p={2}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          bgcolor="neutral.50"
        >
          <Stack textAlign="center" alignItems="center">
            <Typography my={2} variant="h3">
              {t("BinahScanFlow.prompt.title")}
            </Typography>

            <Stack spacing={2}>
              <InformationBox Icon={"HeartIcon"} width={40}>
                <Stack spacing={1} textAlign="left">
                  <Typography variant="h5" fontWeight="600">
                    {t("BinahScanFlow.prompt.informationBox.title")}
                  </Typography>
                  <Typography variant="body2">
                    {t("BinahScanFlow.prompt.informationBox.body")}
                  </Typography>
                </Stack>
              </InformationBox>

              <Box
                sx={{
                  backgroundImage: `url(${PersonDoingFaceScanImage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  minHeight: "203px",
                  mb: 2,
                }}
              />
            </Stack>
          </Stack>
          <Stack direction={"row"} height={42} spacing={1}>
            <ButtonWithAnalytics
              page="BinahScanPrompt"
              text={t("BinahScanFlow.prompt.skipScanButton")}
              intent="navigational"
              variant="outlined"
              fullWidth
              onClick={onDeclineHealthCheckButtonClick}
            >
              {t("BinahScanFlow.prompt.skipScanButton")}
            </ButtonWithAnalytics>
            <ButtonWithAnalytics
              page="BinahScanPrompt"
              text={t("BinahScanFlow.prompt.continueScanButton")}
              intent="navigational"
              fullWidth
              onClick={onContinueHealthCheckButtonClick}
            >
              {t("BinahScanFlow.prompt.continueScanButton")}
            </ButtonWithAnalytics>
          </Stack>
        </Stack>
        <FullscreenBackground color="primary.main" />
      </Stack>
    </>
  );
}
